// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false, 
  firebase: {
    apiKey: "AIzaSyBI1-ddfTxoiebrNTLJVeFX0BBDudSPbyg",
    authDomain: "vn1cham.firebaseapp.com",
    projectId: "vn1cham",
    storageBucket: "vn1cham.appspot.com",
    messagingSenderId: "226694566571",
    appId: "1:226694566571:web:23f68c65e5b0347e185dee",
    measurementId: "G-83P8FPZVVJ"
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
